<template>
  <!-- && typeof labels !== 'undefined' -->
  
  {{facetPageType[pageTargets.knowledgeBase]}}
  
	<div class="search-nav" v-if="facetPageType && labels" id="content-categories" :class="{'disabled': isAllPageWithoutResults}">
		<div class="container dropdown">
			<div class="dropdown-toggle search-nav-toggle hidden-desktop" data-toggle="dropdown"> 
				<p>
					<span>{{labels['searchpageAllResults']}}</span> 
					<em v-if="!isAllPageWithoutResults">({{ allResults }})</em>
				</p>
				<i></i> 
			</div>
			<ul class="dropdown-menu">
				<li class="content-category" data-type="all" v-on:click="selectCategories(false)" v-bind:class="{ 'active':  pageType  === false && !isAllPageWithoutResults}">
					<p>
						<span>{{labels['searchpageAllResults']}}</span>
						<em v-if="!isAllPageWithoutResults">({{ allResults }})</em>
					 </p>
				</li>
				<!-- v-if="facetPageType[pageTargets.product]" -->
				<li data-type="product" class="content-category" v-on:click="selectCategories(pageTargets.product)"  v-bind:class="{ 'active':  pageType  === pageTargets.product }">
					<i></i>
					<p>
						<span>{{labels['searchpageProducts']}}</span> 
						<em v-if="allPageTypes[pageTargets.product]">({{allPageTypes[pageTargets.product]}})</em>
					</p>
				</li>
				<!-- v-if="facetPageType[pageTargets.support]" -->
				<li data-type="software-download" class="content-category" v-on:click="selectCategories(pageTargets.support)"  v-bind:class="{'active':  pageType  === pageTargets.support}">
					<i></i>
					<p>
						<span>{{labels['searchpageSoftwareDownloads']}}</span> 
						<em v-if="allPageTypes[pageTargets.support]">({{allPageTypes[pageTargets.support]}})</em>
					</p>
				</li>
				<!-- v-if="facetPageType[pageTargets.knowledgeBase]" -->
				<li data-type="knowledge-base" class="content-category" v-on:click="selectCategories(pageTargets.knowledgeBase)"  v-bind:class="{'active':  pageType  === pageTargets.knowledgeBase}">
					<i></i>
					<p>
						<span>{{labels['searchpageKnowledgeBase']}}</span> 
						<em v-if="allPageTypes[pageTargets.knowledgeBase]">({{allPageTypes[pageTargets.knowledgeBase]}})</em>
					</p>
				</li>
				<!-- v-if="facetPageType[pageTargets.documents]" -->
				<li data-type="document" class="content-category" v-on:click="selectCategories(pageTargets.documents)"  v-bind:class="{'active':  pageType  === pageTargets.documents}">
					<i></i>
					<p>
						<span>{{labels['searchpageDocuments']}}</span> 
						<em v-if="allPageTypes[pageTargets.documents]">({{allPageTypes[pageTargets.documents]}})</em>
					</p>
				</li>
				<!-- v-if="facetPageType[pageTargets.articles]" -->
				<li data-type="article" class="content-category" v-on:click="selectCategories(pageTargets.articles)"  v-bind:class="{'active':  pageType  === pageTargets.articles}">
					<i></i>
					<p>
						<span>{{labels['searchpageArticles']}}</span> 
						<em v-if="allPageTypes[pageTargets.articles]">({{allPageTypes[pageTargets.articles]}})</em>
					</p>
				</li>
			</ul>
		</div>
	</div>

</template>



<script>
import { eventBus } from './../event-bus.js'

export default {
  props: ['facetPageType', 'pageTargets','allResults', 'pageType','allPageTypes'],
  computed: {
    // Labels must be accessed dynamically
    labels: function () { return window.labels; },
    allPageResultsTotal: function () {
      let total = 0
      for (let quantity in this.facetPageType) {
        total = total += parseInt(this.facetPageType[quantity])
      }
      return total
    },
    isAllPageWithoutResults: function () {
    	return (!this.pageType && this.allResults === null)
    }
  },
  data: function () {
    return {
      activePageType: false
    }
  },
  methods: {
    selectCategories: function (pageType) {
    if(!this.isAllPageWithoutResults){ 
	     this.activePageType = pageType
	     eventBus.$emit('refinePageType', pageType)
     } 	
    }
  }
}
</script>
