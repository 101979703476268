<template>

<div class="search-wrapper" id="SearchPage" :class="{'loading': loading}">
    <search-error v-if="errored"/>
    <loading-search v-if="loading && !errored" />
    <search-heading  v-if="!errored && !loading"
      :mobileRefinementsVisible ="mobileRefinementsVisible"
      :selectedFacets="selectedFacets"
      :searchTerm="searchTerm"
      :spellcheck="spellcheck"
      :spellcheckQ="spellcheckQ"
      :allResults="allResults"
      />
  	<content-category v-if="!errored && !loading"
	  :facetPageType="facetPageType"
	  :pageTargets="pageTargets"
	  :allResults="allResults"
	  :pageType="pageType"
	  :allPageTypes="allPageTypes"
  	/>
	<div class="search-content" v-if="!errored && !loading">
		<div class="container">
			<div class="row-fluid">
				<div class="span3" v-if="leftColumnHasContent">
					<result-keymatch
				      :keyMatches="keyMatches"
				    />
					<refinement-navigation v-if="leftColumnHasFilters"
				      :facetFields="facetFields"
				      :mobileRefinementsVisible ="mobileRefinementsVisible"
				      :selectedFacets="selectedFacets"
				    />
			    </div>
			    <div :class="leftColumnHasContent? 'span9': 'span12'">
				    <searched-filters
				      :selectedFacets="selectedFacets"
				    />
					<results-listing
				      :matchResults="matchResults"
				      :pageTargets="pageTargets"
				      :featuredProducts="featuredProducts"
				      :searchResults="searchResults"
				      :start="start"
				      :keyMatches="keyMatches"
				    />
			    </div>
			</div>
		</div>
	</div>
</div>

</template>



<script>
import axios from 'axios'
import qs from 'qs'
import { eventBus } from './event-bus.js'

import SearchError from './searchpage-components/SearchError.vue'
import LoadingSearch from './searchpage-components/LoadingSearch.vue'
import ContentCategory from './searchpage-components/ContentCategory.vue'
import ResultsListing from './searchpage-components/ResultsListing.vue'
import RefinementNavigation from './searchpage-components/RefinementNavigation.vue'
import SearchHeading from './searchpage-components/SearchHeading.vue'
import SearchedFilters from './searchpage-components/SearchedFilters.vue'
import ResultKeymatch from './searchpage-components/ResultKeymatch.vue'

export default {
  components: {
    'search-error': SearchError,
    'loading-search': LoadingSearch,
    'content-category': ContentCategory,
    'results-listing': ResultsListing,
    'refinement-navigation': RefinementNavigation,
    'search-heading': SearchHeading,
    'searched-filters': SearchedFilters,
    'result-keymatch': ResultKeymatch
  },
  computed: {
  	searchQuery: function(){
  		return window.location.search
  	},
    searchURL: function () {
      return window.location.href
    },
    brand: function () {
      return window.drBrand;
    },
    rcLocaleJS: function () {
      return window.rcLocaleJS;
    },
    labels: function () {
      return window.labels;
    },
    leftColumnHasFilters: function () {
      return (this.facetFields && this.pageType === this.pageTargets.product)
    },
    leftColumnHasContent: function () {
      return (this.leftColumnHasFilters || this.keyMatches.length > 0)
    }
  },
  data: function () {
    // api: '/ww/solrUnifiedSearch?locale=en_us&term=backup&brand=seagate',
    //       /ww/solrUnifiedSearch?brand=seagate&term=backup&locale=en-us
    return {
      api: '/ww/solrUnifiedSearch',
      // api: '/ww/solrUnifiedSearch?locale=en_us&term=backup&brand=seagate',
      facetFields: null,
      facetPageType: null,
      keyMatches: [],
      searchTerm: null,
      spellcheck: null,
      spellcheckQ: null,
      searchResults: [],
      matchResults: null,
      allResults: null,
      pageTargets: {
        articles: 'articles',
        documents: 'documents',
        knowledgeBase: 'knowledgebase',
        support: 'support',
        product: 'pdp',
      },
      pageType: false,
      loading: true,
      errored: false,
      selectedFacets: [],
      start: 20,
      featuredProducts: [],
      featuredProductLimit: 2,
      featuredProductRelevanceFloor: .0,
      mobileRefinementsVisible: false,
      initStatus: true
    }
  },
  created () {
    // This check exists to prevent Vue from initiating api calls on other pages CWS-119.  Due to the non-standard configuration of the main.js file (cwssearch.js) when one component is mounted both components are mounted, even when only the typeahead is actually rendered.
    if (this.searchURL.indexOf('/search/')!=-1) {
    	
      this.initSearchResults()
    }
    //LocalTest
    //this.getSearchResults({})
  },
  mounted () {
  	eventBus.$on('facetsClearedInHeading', () => {
      this.clearFacets()
    }),
    eventBus.$on('facetsClearedInMobileHeading', () => {
      this.clearFacets()
    }),
    eventBus.$on('facetRemovedInHeading', (activeFacet) => {
      this.removeFacet(activeFacet)
      // needs to trigger refineSearchResults or getSearchResults
    }),
    eventBus.$on('facetRemovedInMobileHeading', (activeFacet) => {
      this.removeFacet(activeFacet)
    }),
    eventBus.$on('mobileRefinementMenu', (refinementMenuStatus) => {
      this.mobileRefinementsVisible = refinementMenuStatus;
    }),
    eventBus.$on('loadMoreResults', () => {
      this.loadMoreResults()
    }),
    eventBus.$on('refinementSelectedInNavigation', (activeFacet) => {
      if (this.selectedFacets.filter(facet => facet.uniqueId === activeFacet.uniqueId).length === 0) {
        // missing - adds it
        this.selectedFacets.push(activeFacet)
      } else {
        this.selectedFacets.splice(this.selectedFacets.findIndex(facet => facet.uniqueId === activeFacet.uniqueId), 1)
      }
      this.refineSearchResults()
    }),
    eventBus.$on('refinePageType', (pageType) => {
      this.pageType = pageType
      this.clearFacets()
    })
  },
  methods: {
  	initSearchResults: function() {
      let params = {
        brand: this.brand,
        locale: this.getLocale(),
        term: this.getProp('keyword')!=undefined?this.getProp('keyword'):null,
        ignoreSpellCheck: this.getProp('ignoreSpellCheck')!=undefined?this.getProp('ignoreSpellCheck'):'false'
      }      
      this.getSearchResults(params)
    },
    getLocale: function() {
      let locale = this.rcLocaleJS
      if (locale.indexOf("-") != -1){
        let reformatLocale = locale.replace("-","_")
        return reformatLocale
      } else {
        return locale
      }
    },
    getProp: function(target) {
    	var query = this.searchQuery.substring(1);
       	var vars = query.split("&");
       	for (var i=0;i<vars.length;i++) {
       	var pair = vars[i].split("=");
           if(pair[0] == target){
   				return decodeURIComponent(pair[1]);
           	}
       	}
      	//return new URL(this.searchURL).searchParams.get(target)
    },
    setProp: function(target, value) {
    	var old;
    	var newurl;
    	if(this.getProp(target)!=undefined){
    		old = this.getProp(target)
    		if(value == false){
    			newurl = this.searchURL.replace('&'+ target +'=' + old, '');
    		}else {
    			newurl = this.searchURL.replace('=' + old, '=' + value);
    		} 		
    	}else {
    		if(value != false){
    			newurl = this.searchURL + '&'　+ target + '=' + value;
    		}else {
    			newurl = this.searchURL;
    		}
    	}
       	window.history.replaceState({
			path: newurl
		}, '', newurl);
      	//return new URL(this.searchURL).searchParams.get(target)
    },
    getSearchResults: function(params) {
      //when all, !params['fq_pagetype']==true
      //this.loading = true
      axios
        //.get('http://localhost:3000/general-search-v2.json')
        .get(this.api, 
        	{
        	 params:params
        	 ,paramsSerializer: params => {
			    return qs.stringify(params, {arrayFormat: 'repeat'})
			  }
		  	})
        .then(response => (
            this.searchTerm = response.data.responseHeader!=undefined ? (response.data.responseHeader.params.q || params['term']): params['term'],
            this.spellcheck = response.data.responseHeader!=undefined ? response.data.responseHeader.params.spellcheck : null,
            this.spellcheckQ = response.data.responseHeader!=undefined ? response.data.responseHeader.params['spellcheck.q'] : null,
            this.facetFields = response.data.facet_field!=undefined ? (response.data.facet_field || null) : null,
            this.facetPageType = response.data.facet_field!=undefined ? (response.data.facet_field.pageType || null) : null,
            this.matchResults =  response.data.grouped!=undefined ? (response.data.grouped.pdhInternalNamesGroup.matches || null) : null,
            this.searchResults = response.data.grouped!=undefined ? (response.data.grouped.pdhInternalNamesGroup.groups || []) : [],
            this.featuredProducts = response.data.grouped!=undefined ? (this.featuredProductsCheck(response.data.grouped.pdhInternalNamesGroup.groups) || []) : [],
            this.keyMatches = response.data.keyMatches!=undefined ? (response.data.keyMatches.docs || []) : []

          )
        )
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {    
        	if(this.initStatus && this.getProp('pagetype')!= undefined){
        		this.loading = true
        	}    
        	else {
        		this.loading = false
        	}    	     	
	        if(!this.pageType){
        		this.allResults = this.matchResults
        		this.allPageTypes = this.facetPageType
        	}
        	if(this.initStatus && this.getProp('pagetype')!= undefined){
        		this.pageType = this.getProp('pagetype');
        		this.dupGetSearchResultsForParams(params);
        		this.initStatus = false;
        	}	  
    	})
    },
    dupGetSearchResultsForParams:function(params){
    	if (this.pageType !== false){
        	params['fq_pagetype'] = this.pageType
      	}
      	this.getSearchResults(params)
    },
    // Because top match products are not returned as unique object they must be filtered out:
    // If statements execute left to right, current format may not be best computational order but given the speed of virtual DOM rendering this should not be noticable to the user.
    // this.featuredProductCounter <= this.featureProductLimit: Has the featured product limit already been reached?
    // doc.pageType === pageTargets.product && property: Is result actually a product?
    // doc.score > featureProductRelevanceFloor: Prevents elaborate display of low match products when few results
    featuredProductsCheck: function (searchResults) {
      let target = this.pageTargets.product
      let relevanceFloor = this.featuredProductRelevanceFloor
      let productLimit = this.featuredProductLimit
      let products = []
      let productCounter = 0
      // Counter is related to default value of start because start is modified the value is set here.
      let counterLimit = (searchResults.length < 20) ? (searchResults.length - 1) : 19
      for (let counter = 0; counter < counterLimit; counter++) {
        searchResults[counter].doclist.docs.forEach( function (doc) {
          if (productCounter < productLimit && doc.pageType === target && doc.score > relevanceFloor) {
            // If max products allowed break early
            productCounter += 1
            products.push(doc)
          }
        })
        // If max products is not acheived early
        if(products.length == productLimit){
          return products
        }
      }
    },
    loadMoreResults: function () {
      let params = {
        brand: this.brand,
        locale: this.getLocale(),
        start: this.start,
        term: this.getProp('keyword'),
        ignoreSpellCheck: this.getProp('ignoreSpellCheck')!=undefined?this.getProp('ignoreSpellCheck'):'false',
        fq_pagetype: this.pageType !== false? this.pageType : undefined
      }
      if (this.selectedFacets.length > 0) {
        params = this.buildRefinedParams(params)
      }
      axios
      .get(this.api,
      	{
        	 params:params
        	 ,paramsSerializer: params => {
			    return qs.stringify(params, {arrayFormat: 'repeat'})
			  }
	  	})
        .then(response => {
          this.moreResults = response.data.grouped.pdhInternalNamesGroup.groups
          for (var key in this.moreResults) {
            this.searchResults.push(this.moreResults[key])
          }
        })
        .catch((error) => {
            console.log(error)
            this.errored = true
          })
        .finally(() => this.start += 20, this.loading = false)
    },
    refineSearchResults: function() {
      // resets start on facet select
      this.start = 20
      let params = {
        brand: this.brand,
        locale: this.getLocale(),
        term: this.getProp('keyword'),
        ignoreSpellCheck: this.getProp('ignoreSpellCheck')!=undefined?this.getProp('ignoreSpellCheck'):'false'
      }
      
      this.setProp('pagetype',this.pageType);

      if (this.pageType !== false){
        params['fq_pagetype'] = this.pageType       
      }
      if (this.selectedFacets.length > 0) {
        params = this.buildRefinedParams(params)
      }
      this.getSearchResults(params)
    },
    buildRefinedParams: function (initialParams) {
    	var refinedParams = initialParams;
      	this.selectedFacets.forEach (function (facet) {
        	let exportString = 'fq_'+ (facet.facetField).toLowerCase()     
        	let exportValue = facet.facet;
        	//alert(initialParams[exportString]);
        	if(initialParams[exportString]){             				
        		initialParams[exportString].push(exportValue);
        		//alert("pushValue:"+ initialParams[exportString]);
        	}else {
        		initialParams[exportString] = [];
        		initialParams[exportString].push(exportValue);
        	}
        	//initialParams[exportString] = facet.facet;

      	});
      	return refinedParams
    },
    clearFacets: function () {
      // Empties selected facets array
      this.selectedFacets.splice(0, this.selectedFacets.length)

      this.refineSearchResults()
    },
    removeFacet: function (activeFacetUniqueId) {
      this.selectedFacets.splice(this.selectedFacets.findIndex(facet => facet.uniqueId === activeFacetUniqueId), 1)

      this.refineSearchResults()
    },
    clearSearchResults: function(){
      this.facetFields = null;
      this.facetPageType = null;
      this.keyMatches = [];
      this.searchTerm = null;
      this.spellcheck = null;
      this.spellcheckQ = null;
      this.searchResults = [];
      this.matchResults = null;
      this.allResults = null;
    }
  }
}
</script>
