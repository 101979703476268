<template>
  <div id="product-column" v-if="labels" class="box-right">
    <div class="keymatches" v-if="keyMatches">
        <div class="box-list">
	        <div class="keymatch box-item highlight clearfix" v-for="(keymatch, index) in keyMatches" v-if="index < keymatchLimit" :key="index">
	          <div v-if="keymatch.image" class="item-left" >
	            <a v-bind:href="keymatch.url">
	              <img class="item-object" alt="" :src="keymatch.image">
	            </a>
	          </div>
	          <div class="item-body">
	            <h4 class="product-title item-heading">
	              <a v-bind:href="keymatch.url">{{ formatTitle(keymatch[('title_' + keymatch.lang)]) }}</a>
	            </h4>
	            <!-- Get language from property in for loop, merges it with string, fetches property from that string  -->
	            <p class="keymatch-description"><span class="text">{{ keymatch[('description_' + keymatch.lang)] }}</span></p>
	            <p>
	            <a v-bind:href="keymatch.url">{{labels['typeaheadMoreIn']}}<i class="ss-icon">&#x25BB;</i></a>
				</p>
	          </div>
	        </div>
      </div>
      <div class="overlay" style="width:calc(100% + 20px)" v-if="keyMatches.length > 0"></div>
    </div>
	<div class="products" v-if="productRelevanceScore > productRelevanceFloor">
	    <div class="box-list">
	        <!-- Display product check filters for viable results (pdpTarget or supportPageTarget or supportEOLTarget) -->
	        <div class="product box-item clearfix" v-for="(product, index) in products" :key="index" v-if="index < productLimit && displayProductCheck(product, pdpTarget, supportPageTarget, supportEOLTarget)">
	          <!-- If product has pdp -->
	          <div v-if="checkPageType(product, pdpTarget)" class="product-pdp">
	            <div class="item-left" v-if="getProductData(product, pdpTarget).imagePath">
	              <a :href="getProductData(product, pdpTarget).url">
	                <img class="item-object" :alt="getProductText(product, pdpTarget, 'title_')" :src="getAbsoluteImagePath(getProductData(product, pdpTarget).imagePath)">
	              </a>
	            </div>
	            <div class="item-body">
	                <h4 class="product-title item-heading">
	                  <a :href="getProductData(product, pdpTarget).url"> {{formatTitle(getProductText(product, pdpTarget, 'title_'))}} </a>
	                </h4>
	                <p class="product-description"><span class="text">{{getProductText(product, pdpTarget, 'description_')}}</span></p>
	                <p v-if="getProductData(product, pdpTarget).supportURL" class="support-link"><a :href="getAbsoluteSubcategoryLinkPath(getProductData(product, pdpTarget).supportURL, getProductData(product, pdpTarget).url)"> {{labels['typeaheadSupportLink']}} <i class="ss-icon">&#x25BB;</i> </a></p>
	                <p v-if="getProductData(product, pdpTarget).featuresURL" class="support-link"><a :href="getAbsoluteSubcategoryLinkPath(getProductData(product, pdpTarget).featuresURL, getProductData(product, pdpTarget).url)"> {{labels['typeaheadProductInformation']}} <i class="ss-icon">&#x25BB;</i> </a></p>
	            </div>
	          </div>
	          <div v-if="!checkPageType(product, pdpTarget) && checkPageType(product, supportPageTarget)" class="product-support-only">
	            <div class="item-left" v-if="getProductData(product, supportPageTarget).imagePath">
                  <a :href="getProductData(product, supportPageTarget).url">
                    <img class="item-object" :alt="getProductText(product, supportPageTarget, 'title_')" :src="getAbsoluteImagePath(getProductData(product, supportPageTarget).imagePath)">
                  </a>
                </div>
	            <div class="item-body">
	                <h4 class="product-title item-heading">
	                  <a :href="getProductData(product, supportPageTarget).url"> {{formatTitle(getProductText(product, supportPageTarget, 'title_'))}} </a>
	                </h4>
	                <p class="product-description"><span class="text">{{getProductText(product, supportPageTarget, 'description_')}}</span></p>
	                <p v-if="getProductData(product, supportPageTarget).supportURL" class="support-link">
	                  <a :href="getAbsoluteSubcategoryLinkPath(getProductData(product, supportPageTarget).supportURL, getProductData(product, supportPageTarget).url)"> {{labels['typeaheadSupportLink']}} <i class="ss-icon">&#x25BB;</i> </a>
	                </p>
	                <p v-if="getProductData(product, supportPageTarget).featuresURL" class="support-link">
	                  <a :href="getAbsoluteSubcategoryLinkPath(getProductData(product, supportPageTarget).featuresURL, getProductData(product, supportPageTarget).url)"> {{labels['typeaheadProductInformation']}} <i class="ss-icon">&#x25BB;</i> </a>
	                </p>
	            </div>
	          </div>
	          <div v-if="!checkPageType(product, pdpTarget) && checkPageType(product, supportEOLTarget)" class="product-support-only">
	            <div class="item-left" v-if="getProductData(product, supportEOLTarget).imagePath">
                  <a :href="getProductData(product, supportEOLTarget).url">
                    <img class="item-object" :alt="getProductText(product, supportEOLTarget, 'title_')" :src="getAbsoluteImagePath(getProductData(product, supportEOLTarget).imagePath)">
                  </a>
                </div>
	            <div class="item-body">
	                <h4 class="product-title item-heading">
	                  <a :href="getProductData(product, supportEOLTarget).url"> {{formatTitle(getProductText(product, supportEOLTarget, 'title_'))}} </a>
	                </h4>
	                <p class="product-description"><span class="text">{{getProductText(product, supportEOLTarget, 'description_')}}</span></p>
	                <p v-if="getProductData(product, supportEOLTarget).supportURL" class="support-link">
                      <a :href="getAbsoluteSubcategoryLinkPath(getProductData(product, supportEOLTarget).supportURL, getProductData(product, supportEOLTarget).url)"> {{labels['typeaheadSupportLink']}} <i class="ss-icon">&#x25BB;</i> </a>
                    </p>
                    <p v-if="getProductData(product, supportEOLTarget).featuresURL" class="support-link">
                      <a :href="getAbsoluteSubcategoryLinkPath(getProductData(product, supportEOLTarget).featuresURL, getProductData(product, supportEOLTarget).url)"> {{labels['typeaheadProductInformation']}} <i class="ss-icon">&#x25BB;</i> </a>
                    </p>
	            </div>
	          </div>
	        </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
  	formatTitle: function (title) {
  		var lastword = title.lastIndexOf("|");
		var str = lastword != -1? title.substring(0, lastword): title;
		if(str.lastIndexOf("|")!= -1){
			last = str.lastIndexOf("|");
			str = str.substring(0, last);
		}
		return str;
  	},
  	setEllipsisForTypeahead: function () {
        var priorHeight = 36;        
        vjQuery("#search-typeahead .keymatch-description,#search-typeahead .product-description").removeClass("ellipsis-fade"); 
        vjQuery("#search-typeahead .keymatch-description,#search-typeahead .product-description").each(function(){
            vjQuery(this).css("height","auto");
            var height = vjQuery(this).find(".text").height();
            if(height >= priorHeight) {
                vjQuery(this).addClass("ellipsis-fade");
                vjQuery(this).css("height",priorHeight +"px"); 
            }else {
                vjQuery(this).removeClass("ellipsis-fade");
                vjQuery(this).css("height","auto");
            }
        });
    },
    displayProductCheck: function(product, pdpTarget, supportPageTarget, supportEOLTarget) {
      return (this.checkPageType(product, pdpTarget) || this.checkPageType(product, supportPageTarget) || this.checkPageType(product, supportEOLTarget) )
    },
    checkPageType: function(product, targetPageType) {
      return (typeof this.getProductData(product, targetPageType) !== "undefined")
    },
    getProductData: function (product, targetPageType) {
      let result = (product.doclist.docs.filter(obj => {
        return obj.pageType === targetPageType
      })[0])
      return result
    },
    // Used for properties that require a language like description, or title
    getProductText: function (product, targetPageType, unmodifiedProperty) {
      let assembledProperty = this.getProductData(product, targetPageType)
      // Get language from property in for loop, merges it with string, fetches property from that string
      return assembledProperty[(unmodifiedProperty + assembledProperty.lang)]
    },
    imagePresent: function (result) {
      return (
        result.hasOwnProperty('image')
      )
    },
    getAbsoluteImagePath: function(imagePath){
        if (typeof(imagePath) != "undefined") {
            const path = "https://www.seagate.com"; 
            if (imagePath.indexOf('http') == 0) {
                return imagePath;
            }else {
            	return (path + imagePath);
            }
        }
    },
	getAbsoluteSubcategoryLinkPath: function(relativePath, parentURL){
	    const parser = document.createElement('a');
	    parser.href = parentURL;
	    return parser.protocol + "//" + parser.hostname + relativePath;
	}
  },
  props: ['products', 'productLimit', 'productRelevanceFloor', 'productRelevanceScore', 'keyMatches', 'keymatchLimit'],
  computed: {
    // Labels must be accessed dynamically
    labels: function () { return window.labels; }
  },
  data () {
    return {
      supportEOLTarget: 'support_eol',
      supportPageTarget: 'support',
      pdpTarget: 'pdp'
    }
  },
  mounted: function (){
  	this.$nextTick(function(){
        this.setEllipsisForTypeahead();
    }),
    vjQuery(window).on('resize', this.setEllipsisForTypeahead);
  },
  watch: {
    products: function(newValue, oldValue){
    	//console.log("old:" + oldValue)
    	//console.log("new:" + newValue)
    	this.$nextTick(function(){
            this.setEllipsisForTypeahead();
        })
    },
    keyMatches: function(newValue, oldValue){
    	//console.log("old:" + oldValue)
    	//console.log("new:" + newValue)
    	this.$nextTick(function(){
            this.setEllipsisForTypeahead();
        })
    }
  }
}
</script>
