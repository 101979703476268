// Uncomment if it is necessary to use devtools in production mode
//Vue.config.devtools = true

import Vue from 'vue'
import jQuery from 'jquery'
window.vjQuery = jQuery 
import SearchPage from './components/searchpage.vue'
import TypeAhead from './components/typeahead.vue'
// Will not create CSS file on build unless "import Less from './assets/less/app.less'" is present.
//import Less from './assets/less/app.less'

// Why new Vue is declared twice
// https://forum.vuejs.org/t/augmenting-an-existing-application-with-components/16104/4
new Vue({
  el: "type-ahead",
  render: h => h(TypeAhead)
})

// This prevents an issue where the searchpage mounts twice and send out two api requests.  This behavior is not related to declaring new Vue twice.
if (document.querySelector('search-page')!== null){
  new Vue({
    el: "search-page",
    render: h => h(SearchPage)
  })
}

// According to the documentation the following is considered the correct way structure this file.  For reasons not entirely understood this does not work in default.jsp.
// The best guess is the ^above^ method works because Vue is intended to be injected into an html page. The other elements present in the wrapping element are potentially controlled by the virtual DOM, but ignored.
// Its possible the virtual DOM breaks because its behavior conflicts with how .jsp works creating content.
// Vue can work INSIDE a JSP template, but not wrapped AROUND a JSP template because the virtual DOM doesn't understand what to do with it.
// By targeting elements directly VUE is pointed at code it knows how to control
// if (document.querySelector('#vue-wrapper') !== null){
  // new Vue({
  //   el: "#vue-wrapper",
  //   components: {
  //     'type-ahead': require('./components/typeahead.vue'),
  //     'search-page': require('./components/searchpage.vue')
  //   }
  // })
// }

// How jsp should roughly be structured. Note prescene of inline template
// <body>
//   <div id='vue-wrapper' inline-template>
//     other content
//     <type-ahead></type-ahead>
//      other content
//     <search-page></search-page>
//   </div>
// </body>
