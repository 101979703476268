<template>
	<div id="search-error" class="search-error">
		<div class="container">
  			<div class="search-list"><h5 v-if="labels">{{ labels['searchpageError'] }}</h5></div>
  		</div>
  	</div>
</template>

<script>
export default{
  computed: {
    // Labels must be accessed dynamically
    labels: function () { return window.labels; }
  }
}
</script>
