<template>

<div class="search-keymatchs" id="search-keymatchs" v-if="keyMatches && keyMatches.length > 0">
    <div class="search-keymatch" v-for="(doc, index) in keyMatches" v-if="index == 0">
      <div class="image" v-if="imageCheck(doc)">
        <a :href="doc.url"><img :src="getAbsoluteImagePath(doc.image)" :alt="doc[('title_' + doc.lang)]"></a>
      </div>
      <div class="text">
        <h6 class="search-keymatch-title"><a :href="doc.url">{{doc[('title_' + doc.lang)]}}</a></h6>
        <p class="search-keymatch-desc">{{doc[('description_' + doc.lang)]}}</p>
        <p><a v-bind:href="doc.url">{{labels['typeaheadMoreIn']}}<i class="ss-icon">&#x25BB;</i></a></p>
      </div>
    </div>
</div>

</template>



<script>
import { eventBus } from './../event-bus.js'

export default {
  props: ['keyMatches'],
  computed: {
    // Labels must be accessed dynamically
    labels: function () { return window.labels; },
    rcLocaleJS: function () { return window.rcLocaleJS; }
  },
  methods: {
  	getAbsoluteImagePath: function(imagePath){
      	if (typeof(imagePath) != "undefined") {
      		const path = "https://www.seagate.com"; 
      		let index = imagePath.indexOf('/www-content/');
      		if(index == 0){
      			return (path + imagePath);
      		} else if (imagePath.indexOf('http') == 0) {
      		    return imagePath;
      		}
  		}
  	},
    imageCheck: function (doc) {
      return (typeof doc.image != 'undefined')
    }
  }
}
</script>
