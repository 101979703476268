<template>
	<div id="no-results" class="no-results" style="min-height: calc(100vh - 593px)" v-if="labels">
		<h4>{{labels['search.NoResults']}}</h4>
		
		<h5>{{labels['search.SomeSuggestions']}}</h5>
		<ul>
			<li>{{labels['search.MakeSure']}}</li>
			<li>{{labels['search.TryDifferent']}}</li>
			<li>{{labels['search.TryMore']}}</li>
		</ul>
	</div>
	
</template>

<script>
export default{
  computed: {
    // Labels must be accessed dynamically
    labels: function () { return window.labels; }
  }
}
</script>
