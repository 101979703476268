<template>
	<div class="loading-box">
		<div class="overlay"></div>
		<!--<p v-if="labels">{{ labels['searchpageLoadingMessage'] }}</p>-->
		<div class="placeholder">
			<div class="search-header">
				<div class="container">
					<p class="search-info"></p>
					<div class="search-header-box">							
					</div>
				</div>
			</div>
			<div class="search-nav">
				<div class="container dropdown">
					<div class="dropdown-toggle search-nav-toggle hidden-desktop">
						<p></p>							
					</div>
					<ul class="dropdown-menu">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
			<div class="search-content">
				<div class="container">
					<div class="row-fluid">								
						<div class="span12">
							<div class="search-list">									
								<div class="search-items">
									<div class="search-item">
										<div class="item-body">
											<h6><a href=""></a></h6>
											<p></p>
										</div>
									</div>
									<div class="search-item">
										<div class="item-body">
											<h6><a href=""></a></h6>
											<p></p>
										</div>
									</div>
									<div class="search-item">
										<div class="item-body">
											<h6><a href=""></a></h6>
											<p></p>
										</div>
									</div>
									<div class="search-item">
										<div class="item-body">
											<h6><a href=""></a></h6>
											<p></p>
										</div>
									</div>
									<div class="search-item">
										<div class="item-body">
											<h6><a href=""></a></h6>
											<p></p>
										</div>
									</div>
									<div class="search-item">
										<div class="item-body">
											<h6><a href=""></a></h6>
											<p></p>
										</div>
									</div>
									<div class="search-item">
										<div class="item-body">
											<h6><a href=""></a></h6>
											<p></p>
										</div>
									</div>
								</div>
								<button class="load-more-button"></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
 	</div>
</template>

<script>
export default{
  computed: {
    // Labels must be accessed dynamically
    labels: function () { return window.labels; }
  }
}
</script>
