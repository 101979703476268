<template>

<div class="search-list" id="search-body">
     <!-- if high match product -->
	<div class="search-products" v-if="featuredProducts && featuredProducts.length > 0">
		<h4>Products</h4>
		<div class="row-fluid" id="rollup-products">
			<div class="span4" v-for="doc in featuredProducts" :key="doc.id" >
				<div class="search-product search-item rollup-product">
					<div class="product-image" v-if="doc.imagePath">
						<a :href="doc.url"><img :src="getAbsoluteImagePath(doc.imagePath)" :alt="doc[('title_' + doc.lang)]"></a>
					</div>
					<div class="product-content product-text-box">
						<h6 class="search-result-title"><a :href="doc.url">{{doc[('title_' + doc.lang)]}}</a></h6>
						<p class="search-result-desc">{{doc[('description_' + doc.lang)]}}</p>
                		<p v-if="doc.supportURL"><a class="search-results-link" :href="getAbsoluteSubcategoryLinkPath(doc.supportURL, doc.url)" alt=""> {{labels['searchpageSupportLink']}} <i class="ss-icon">&#x25BB;</i></a></p>
                		<p v-if="doc.featuresURL"><a class="search-results-link" :href="getAbsoluteSubcategoryLinkPath(doc.featuresURL, doc.url)" alt=""> {{labels['searchpageProductInformation']}} <i class="ss-icon">&#x25BB;</i></a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div id="results" class="search-items" v-if="searchResults">
		<div v-for="(result, index) in searchResults" v-if="index < start" :key="index">
            <div v-for="doc in result.doclist.docs" :key="doc.id">
				<div class="search-item result" :data-type="selectType(doc)" v-if="doc.pageType != 'pdp' && doc.pageType != 'support' && doc.pageType != 'support_eol'">
					<div class="item-body">
						<h6 class="search-result-title">
							<a v-if="doc[('title_' + doc.lang)]" :href="doc.url">{{doc[('title_' + doc.lang)]}}</a>
							<a v-if="!doc[('title_' + doc.lang)]" :href="doc.url">{{doc.url}}</a>
						</h6>
						<p class="search-result-desc">{{doc[('description_' + doc.lang)]}}</p>
					</div>
				</div>
				<div class="search-item" :data-type="selectType(doc)" v-if="featuredProducts ? productCheck(doc): (doc.pageType === 'pdp' || doc.pageType === 'support' || doc.pageType === 'support_eol')">
					<div class="item-body">
						<h6 class="search-result-title"><a :href="doc.url">{{doc[('title_' + doc.lang)]}}</a></h6>
						<p class="search-result-desc">{{doc[('description_' + doc.lang)]}}</p>
                    	<p v-if="doc.supportURL"><a class="search-results-link" :href="getAbsoluteSubcategoryLinkPath(doc.supportURL, doc.url)" alt=""> {{labels['searchpageSupportLink']}} <i class="ss-icon">&#x25BB;</i></a></p>
                    	<p v-if="doc.featuresURL"><a class="search-results-link" :href="getAbsoluteSubcategoryLinkPath(doc.featuresURL, doc.url)" alt=""> {{labels['searchpageProductInformation']}} <i class="ss-icon">&#x25BB;</i></a></p>
					</div>
					<div class="item-image" v-if="doc.imagePath">
						<a :href="doc.url"><img :src="getAbsoluteImagePath(doc.imagePath)" :alt="doc[('title_' + doc.lang)]"></a>
					</div>
				</div>
			</div>
		</div>
	</div>

	<button class="load-more-button" id="load-more" v-on:click="loadMore()" v-if="start < matchResults"> {{labels['searchpageLoadMore']}} </button>
	
	<no-results v-if="!errored && !loading && !searchResults.length && !keyMatches.length" />
</div>

</template>



<script>
import { eventBus } from './../event-bus.js'
import NoResults from './NoResults.vue'

export default {
  components: {
    'no-results': NoResults
  },
  props: ['matchResults','pageTargets', 'searchResults','keyMatches', 'start', 'featuredProducts', 'errored', 'loading'],
  computed: {
    // Labels must be accessed dynamically
    labels: function () { return window.labels; },
    rcLocaleJS: function () { return window.rcLocaleJS; }
  },
  methods: {
  	getAbsoluteImagePath: function(imagePath){
  	    if (typeof(imagePath) != "undefined") {
            const path = "https://www.seagate.com"; 
            if (imagePath.indexOf('http') == 0) {
                return imagePath;
            }else {
            	return (path + imagePath);
            }
        }
  	},
    getAbsoluteSubcategoryLinkPath: function(relativePath, parentURL){
      const parser = document.createElement('a');
      parser.href = parentURL;
      return parser.protocol + "//" + parser.hostname + relativePath;
    },
  	selectType: function (doc) {
 		let type = "";
      	if(doc.pageType == "pdp")
      		type="product"; 
  		else if(doc.pageType == "support" || doc.pageType == "support_eol") {
  		    if(doc.url.indexOf("/support/downloads/")!= -1 || doc.url.indexOf("/support/software/")!= -1){
  		        type="software-download";
  		    }else {
  		        type="product"; 
  		    }
  		}    		
  		else if(doc.pageType == "knowledgebase") 
      		type="knowledge-base"; 
  		else if(doc.pageType == "product") 
      		type="document"; 
  		else if(doc.pageType == "press" || doc.pageType == "article") 
      		type="article"; 
      	else {
      	    if(doc.url.indexOf("/support/downloads/")!= -1){
                type="software-download";
            }else {
                type="general";
            }
      	}		
      	return type;
    },
    getIcon: function (pageType) {
      // Identifies icon filtering through object pageTargets
      const pageIcon = (Object.keys(this.pageTargets) ).filter(pageTarget => this.pageTargets[pageTarget] === pageType)[0]
      return pageIcon + 'Icon'
    },
    imageCheck: function (doc) {
      return (typeof doc.image != 'undefined')
    },
    productCheck: function (doc) {
      return (((this.featuredProducts).filter(result => result.id === doc.id).length === 0) && (doc.pageType === 'pdp' || doc.pageType === 'support' || doc.pageType === 'support_eol'))
    },
    loadMore: function () {
      eventBus.$emit('loadMoreResults')
    }
  }
}
</script>
