<template>
        <!-- for each result in category -->
        <div id="term-column" v-if="labels" class="box-left">
        	<div v-if="suggestions && suggestions.length > 0" id="popular-searches" class="prior-list term-section">
	        	<ul>
	              <!-- class search-target is used js for targeting -->
	              <li v-for="(suggestion, index) in suggestions" v-if="index < suggestionsLimit" :key="index" :value="suggestion" class="search-target search-term">
	              <a v-bind:href="'/search/?keyword=' + encodeURIComponent(suggestion)"> {{ suggestion }} </a>
	              </li>
	            </ul>
        	</div>
        	<div v-if="knowledgeBase && knowledgeBase.score > knowledgeBaseRelevanceFloor" id="knowledge-base" class="knowledge-base kds-list term-section">
        		<p>{{labels['typeaheadKnowledgeBaseHeading']}}</p>
        		<ul>
        		<!-- class search-target is used js for targeting -->
        		<li v-for="(doc, index) in knowledgeBase.docs" v-if="index < knowledgeBaseLimit" :key="index" :value="doc[('title_' + doc.lang)]" class="search-target target-link">
        		<a v-bind:href="doc.url"> {{ formatTitle(doc[('title_' + doc.lang)]) }} </a>
        		</li>
              	<!-- <li class="search-target target-link" value="More in Knowledge Base"><a href="" class="more-in">{{labels['typeaheadMoreIn']}} <i class="ss-icon">&#x25BB;</i></a></li> -->          
        		</ul>
        	</div>
        	<div v-if="documentation && documentation.score > documentationRelevanceFloor" id="documentation" class="documentation kds-list term-section">
        		<p>{{labels['typeaheadDocumentationHeading']}}</p>
        		<ul>
        		<!-- class search-target is used js for targeting -->
              	<li v-for="(doc, index) in documentation.docs" v-if="index < documentationLimit" :key="index" :value="doc[('title_' + doc.lang)]" class="search-target target-link">
             	<a v-bind:href="doc.url"> {{ formatTitle(doc[('title_' + doc.lang)]) }} </a>
              	</li>
         	 	<!-- <li class="search-target target-link" value="More in Documentation"><a href="" class="more-in">{{labels['typeaheadMoreIn']}} <i class="ss-icon">&#x25BB;</i></a></li> -->
        		</ul>
        	</div>
        	<div v-if="softwareDownloads && softwareDownloads.score > softwareDownloadsRelevanceFloor" id="software-downloads" class="software-downloads kds-list term-section">
        		<p>{{labels['typeaheadSupportDownloadsHeading']}}</p>
        		<ul>
        		<!-- class search-target is used js for targeting -->
              	<li v-for="(doc, index) in softwareDownloads.docs" v-if="index < softwareDownloadsLimit" :key="index" :value="doc[('title_' + doc.lang)]" class="search-target target-link">
                <a v-bind:href="doc.url"> {{ formatTitle(doc[('title_' + doc.lang)]) }} </a></li>
              	<!-- <li class="search-target target-link" value="More in Software Downloads"><a href="" class="more-in">{{labels['typeaheadMoreIn']}} <i class="ss-icon">&#x25BB;</i></a></li> -->
        		</ul>
        	</div>
        	<div v-if="articles && articles.score > articlesRelevanceFloor" id="articles" class="articles kds-list term-section">
        		<p>{{labels['searchpageArticles']}}</p>
        		<ul>
        		<!-- class search-target is used js for targeting -->
              	<li v-for="(doc, index) in articles.docs" v-if="index < articlesLimit" :key="index" :value="doc[('title_' + doc.lang)]" class="search-target target-link">
              	<a v-bind:href="doc.url"> {{ formatTitle(doc[('title_' + doc.lang)]) }} </a></li>
              	<!-- <li class="search-target target-link" value="More in Articles"><a href="" class="more-in">{{labels['typeaheadMoreIn']}} <i class="ss-icon">&#x25BB;</i></a></li> -->
        		</ul>
        	</div>
        </div>
</template>

<script>
export default {
  props: ['documentation', 'knowledgeBase', 'softwareDownloads','articles', 'suggestions', 'keyMatches', 'knowledgeBaseLimit', 'knowledgeBaseRelevanceFloor', 'documentationLimit', 'documentationRelevanceFloor', 'softwareDownloadsLimit', 'softwareDownloadsRelevanceFloor', 'suggestionsLimit','articlesLimit', 'articlesRelevanceFloor'],
  computed: {
    // Labels must be accessed dynamically
    labels: function () { return window.labels; } 
  },
  methods: { 	
  	formatTitle: function (title) {
  		var lastword = title.lastIndexOf("|");
		var str = lastword != -1? title.substring(0, lastword): title;
		return str;
  	}
  }
}
</script>
