<!-- Refinement Navigation Section Notes:
Due to the fact that:
A) Seagate.com is based on a responsive grid system.
B) The refinement menu behaves as a flyout on mobile and a left nav element on desktop

Duplicate menus have been created. While it is possible to code css to have one element fulfill both functions this as not advised as hiding / showing the menu and repositioning it can cause issues if the user changes their window size.

Because of this design decision, there is a degree of complexity required in the JS to facilitate communciation and update mobile refinement flyout, refinement left nav, and search heading at the same time.  This is addressed by the use of eventBus calls.
-->

<template>

   	<div id="refinements" class="search-filters clearfix"  :class="{'filter-visible' : isOpen}">
	   	<h4>
	   		<span class="search-filter-toggle" v-on:click="openFilter">{{labels['searchpageFilters']}}</span>
	   		<span class="search-filter-close" v-on:click="closeFilter"></span>
	   	</h4>	
		<div class="search-filter-list">
			<div class="search-filter refinement-group" v-if="facetFieldKey != pageType && Object.keys(facetFields[facetFieldKey]).length !== 0" v-for="(facetField, facetFieldKey) in facetFields" :key="facetFieldKey">
				<p>
					{{labels[facetFieldKey.toUpperCase() + '_KEY'] || facetFieldKey}}
				</p>
				<div class="control-group">
					<div class="controls">
					 	<div class="checkbox" v-for="(facetValue, facetKey) in facetField" :key="facetKey" :class="{ 'inactive-filter' : inactiveFacetCheck(facetValue) === true}" v-on:click="toggleFacet(facetKey, facetFieldKey)">
							<input type="checkbox" class="facet checkbox-custom" :id="'refinement' + facetKey + facetFieldKey" :ref="'refinement'+ facetKey + facetFieldKey" :value="facetKey + facetFieldKey"  :disabled="inactiveFacetCheck(facetValue)">
							<label :for="facetKey + facetFieldKey"><span><i class="ss-icon">&#x2713;</i></span>{{labels[facetKey] || facetKey}} ({{facetValue}}) </label>
						</div>
					</div>
				</div>
			</div>
			<div id="refinement-footer" class="hidden-desktop search-btns" v-if="labels">
				<!--<button class="btn btn-default" v-on:click="clearActiveFacets">{{labels['searchpageClear']}}</button>
		      	<button class="btn btn-primary" v-on:click="closeFilter">{{labels['searchpageApply']}}</button>-->
		    </div>
		</div>
	</div>

</template>

<script>
import { eventBus } from './../event-bus.js'

export default {
  props: ['selectedFacets', 'mobileRefinementsVisible', 'facetFields'],
  computed: {
    // Labels must be accessed dynamically
    labels: function () { return window.labels; }
  },
  data: function () {
    return {
      displayedFacetGroups: [],
      // Page Type filter group should only be displayed at top
      pageType: 'pageType',
      refinementMenuStatus: false,
      isOpen: false
    }
  },
  mounted () {
    eventBus.$on('facetsClearedInHeading', () => {
      this.clearActiveFacets()
    }),
    eventBus.$on('refinePageType', () => {
      this.clearActiveFacets()
    }),
    eventBus.$on('facetRemovedInHeading', (activeFacetUniqueId) => {
      this.removeFacet(activeFacetUniqueId)
      // needs to trigger refineSearchResults or getSearchResults
    })
  },
  methods: {
  	openFilter: function(e){
  		this.isOpen = true;
  	},
  	closeFilter: function(e){
  		this.isOpen = false;
  	},
	hideMobile: function () {
      eventBus.$emit('mobileRefinementMenu', !this.mobileRefinementsVisible)
    },
    facetGroupIsExpanded: function (key) {
      return this.displayedFacetGroups.indexOf(key) !== -1
    },
    toggleFacetGroups: function (key) {
      if (this.facetGroupIsExpanded(key)) {
        this.displayedFacetGroups.splice(this.displayedFacetGroups.indexOf(key), 1)
      } else {
        this.displayedFacetGroups.push(key)
      }
    },
    inactiveFacetCheck: function (facetValue) {
      return facetValue === 0
    },
    toggleFacet: function (facetKey, facetField) {
      // Because the html is screen width responsive and both menus exist at all times this code updates the checkbox element for the filter in both menus at the same time. This is redundant, because it is repeating the user's command for the navigation the user is currently interacting with. While this doesn't affect performance this section can be DRYed up.
      let targetFacet = facetKey + facetField
      let activeFacet = {
        facet: facetKey,
        facetField: facetField,
        uniqueId: targetFacet
      }

      // Checkboxes interacting with checkboxes in other menu
      if (this.selectedFacets.filter(facet => facet.uniqueId === activeFacet.uniqueId).length === 0) {
        //this.$refs['mobile-refinement' + targetFacet][0].checked = true
        this.$refs['refinement' + targetFacet][0].checked = true
      } else {
        // console.log(this.$refs['mobile-refinement' + targetFilter])
        //this.$refs['mobile-refinement' + targetFacet][0].checked = false
        this.$refs['refinement' + targetFacet][0].checked = false
      }
      // Display / remove filter in heading
      eventBus.$emit('refinementSelectedInNavigation', activeFacet)
    },
    activeFacetsClearedMobile: function (){
      this.clearActiveFacets()
      eventBus.$emit('facetsClearedInMobileHeading')
    },
    clearActiveFacets: function () {
      let allFacets = document.getElementsByClassName('facet')
      for (var i = 0; i < allFacets.length; i++){
        allFacets[i].checked = false;
      }
    },
    activeFacetRemovedMobile: function(activeFacetUniqueId){
      this.removeFacet(activeFacetUniqueId)
      eventBus.$emit('facetRemovedInMobileHeading', activeFacetUniqueId)
    },
    removeFacet: function (activeFacetUniqueId) {
      //this.$refs['mobile-refinement' + activeFacetUniqueId][0].checked = false
      this.$refs['refinement' + activeFacetUniqueId][0].checked = false
    },
    updateFacet: function(){
      	for (var i = 0; i < this.selectedFacets.length; i++){
        	var targetFacet = this.selectedFacets[i].uniqueId;
        	this.$refs['refinement' + targetFacet][0].checked = true
      	}
    }
  },
  watch: {
    facetFields: function(newValue, oldValue){
    	//console.log("old:" + oldValue)
    	//console.log("new:" + newValue)
    	this.$nextTick(function(){
            this.updateFacet();
        })
    }
  }
}
</script>
