<template>
<div id="search-typeahead" ref="searchTypeahead" class="nm-search nm-icon"  v-click-outside="contractBar" v-if="labels">
	<div class="nm-icon-container clearfix" v-on:click="expandBar">
		<span></span>
		<div>{{labels['typeaheadSearch']}}</div>
	</div>
	<a href="" class="nm-search-close" v-on:click.stop.prevent="contractBar"><span></span></a>
	<div class="nm-search-box" ref="searchBarContainer">
	<!-- @submit.prevent="handleSubmit" action="/search/" method="post"  -->
		<form class="search-bar-form" >
			<input id="search-input"
			type="text"
			v-model="query"
			autocomplete="off"
			class="nm-search-input"
			:placeholder="placeholderValue"
			v-on:keyup="keyPress"
			 />
			 <!-- type="submit" -->
			<input v-on:click="handleSubmit" id="search-submit" class="nm-search-btn nm-search-submit hidden" />
		</form>
	</div>
	<div class="nm-search-bg"></div>
	<div id="typeahead-dropdown-wrapper" class="nm-search-drop" :class="{'drop-visible': dropIsVisible && (leftColumnHasContent || rightColumnHasContent)}">
    	<div id="typeahead-dropdown" class="nm-search-drop-box" v-if="!loading">
      		<consumer-terms-column v-if="leftColumnHasContent"
	        :keyMatches="keyMatches"
	        :documentation="documentation"
	        :documentationLimit="documentationLimit"
	        :documentationRelevanceFloor="documentationRelevanceFloor"
	        :knowledgeBase="knowledgeBase"
	        :knowledgeBaseLimit="knowledgeBaseLimit"
	        :knowledgeBaseRelevanceFloor="knowledgeBaseRelevanceFloor"
	        :softwareDownloads="softwareDownloads"
	        :softwareDownloadsLimit="softwareDownloadsLimit"
	        :softwareDownloadsRelevanceFloor="softwareDownloadsRelevanceFloor"
	        :articles="articles"
	        :articlesLimit="articlesLimit"
	        :articlesRelevanceFloor="articlesRelevanceFloor"
	        :suggestions="suggestions"
	        :suggestionsLimit="suggestionsLimit"
	        >
	      </consumer-terms-column>
	      <product-column v-if="rightColumnHasContent"
	        :products="products"
	        :productLimit="productLimit"
	        :productRelevanceFloor="productRelevanceFloor"
	        :productRelevanceScore="productRelevanceScore"
	        :keyMatches="keyMatches"
	        :keymatchLimit="keymatchLimit"
	        >
	      </product-column>
	    </div>
	  </div>
	</div>
</template>

<script>
import axios from 'axios'
import ClickOutside from 'vue-click-outside'
import ConsumerTermsColumn from './typeahead-columns/ConsumerTermsColumn.vue'
import ProductColumn from './typeahead-columns/ProductColumn.vue'

export default {
  directives: {
    ClickOutside
  },
  components: {
    'consumer-terms-column': ConsumerTermsColumn,
    'product-column': ProductColumn
  },

  data: function () {
    return {
      //'https://wwwstgedit.seagate.com/ww/visualSolrAutoSuggest',
     	api: '/ww/visualSolrAutoSuggest',
      JSONFile: null,
      keyMatches: null,
      query: '',
      originalQuery: '',
      loading: true,
      // Suggestions
      suggestions: null,
      suggestionsLimit: 6,
      // Knolwledge Base
      knowledgeBase: null,
      knowledgeBaseLimit: 3,
      knowledgeBaseRelevanceFloor: 0,
      // Documentation
      documentation: null,
      documentationLimit: 3,
      documentationRelevanceFloor: 0,
      // Software Downloads
      softwareDownloads: null,
      softwareDownloadsLimit: 3,
      softwareDownloadsRelevanceFloor: 0,
       // Articles
      articles: null,
      articlesLimit: 2,
      articlesRelevanceFloor: 0,
      // Keymatches
      keymatchLimit: 1,
      // Product
      products: [],
      productLimit: 3,
      productRelevanceFloor: 0,
      productRelevanceScore: null,
      dropIsVisible: false
    }
  },
  // Might need to become watch
  computed: {
  	// Labels must be accessed dynamically
    labels: function () { return window.labels; },
    placeholderValue: function() {return this.labels['typeaheadPlaceholder'];},
    leftColumnHasContent: function () {
      return (
               (this.suggestions && this.suggestions.length > 0) ||
               (this.documentation && this.documentation.docs.length > 0 && (this.documentation.score > this.documentationRelevanceFloor)) ||
               (this.knowledgeBase && this.knowledgeBase.docs.length > 0 && (this.knowledgeBase.score > this.knowledgeBaseRelevanceFloor)) ||
               (this.softwareDownloads && this.softwareDownloads.docs.length > 0 && (this.softwareDownloads.score > this.softwareDownloadsRelevanceFloor))||
               (this.articles && this.articles.docs.length > 0 && (this.articles.score > this.articlesRelevanceFloor))
             )
    },
    rightColumnHasContent: function () {
      return ((this.productRelevanceFloor < this.productRelevanceScore && this.products.length > 0 && this.products) ||
              (this.keyMatches && this.keyMatches.length >= 1) )
    },
    rcLocaleJS: function () { return window.rcLocaleJS; },
  },
  mounted: function (){
  	this.initializeWidth();
  	vjQuery(window).on('load', this.initializeWidth);
  	vjQuery(window).on('resize', this.triggerResize);
  },
  destroy: function () {
	vjQuery(window).off('resize');
  },
  methods: { 	
  	initializeWidth: function () {
  		var container = vjQuery(".nav-mega #search-typeahead");
		container.css('width', this.getSearchBoxWidth() + "px");
  	},
	triggerResize: function () {
	  	var container = vjQuery(".nav-mega #search-typeahead");
		var searchBoxWidth = this.getSearchBoxWidth();
		var drop = container.find(".nm-search-drop");
		container.width(searchBoxWidth);
	},
  	animateSearchBox: function (container) {
  		var t = this;
	 	container.animate({
			width: t.getSearchBoxWidth()
		},400);
	},
	getSearchBoxWidth: function () {
		var expandWidth = 0;
		if(vjQuery(".hidden-desktop").css("display")!="none"){
			expandWidth = "100%";
		}
		else {
			expandWidth = vjQuery(".nav-mega > .container").outerWidth() - vjQuery(".nm-logo").outerWidth() - vjQuery(".nm-globe").outerWidth() - vjQuery(".nm-user").outerWidth() - 44;
			if(vjQuery(".nm-container").hasClass("dv-194")){
				 expandWidth = expandWidth - 8;
			}
		}
		return expandWidth;
	},
    expandBar: function (e) {
    	var t = this;
	    var self = e.currentTarget;
	    var container = vjQuery(self).parent("#search-typeahead");
	    if(!container.hasClass("expand")){
			var iconWidth = vjQuery(self).outerWidth();
			container.addClass("expand");
			if(vjQuery('.nm-app').html()!=""){
				vjQuery('.nm-app').addClass('force-hide');
			}
			this.animateSearchBox(container);
			var box = container.find(".nm-search-box");
			box.find("input").focus();
		    //window.addEventListener('keyup', this.keyPress);
	    }
	    else {
	    	this.originalQuery = this.query
	    	t.handleSubmit();
	    }
    },
    // Is triggered by dependency ClickOutside, note how call syntax varies slightly
    contractBar: function (e) {
    	var self = e.currentTarget;
      var container = vjQuery('#search-typeahead');
		var drop = container.find(".nm-search-drop");
		var box = container.find(".nm-search-box");
		//if(drop.hasClass("drop-visible")){
		//	drop.removeClass('drop-visible');
		//}
		box.find("input").blur();
		this.query = '';
		container.removeClass("expand");
		//window.removeEventListener('keyup', this.keyPress)
		if(vjQuery('.nm-app').html()!=""){
			vjQuery('.nm-app').removeClass('force-hide');
		}

        // These are here to clear out the TA for next time it's opened
        this.clearResults();
    },
    isClassPresent: function (elementClass) {
      return (document.getElementsByClassName(elementClass).length > 0)
    },    
    keyPress: function (event) {   
      //For languages that require an IME (Chinese, Japanese, Korean etc.), 
      //you’ll notice that v-model doesn’t get updated during IME composition. 
      //If you want to cater for these updates as well, use input event instead. 	
      this.query = event.target.value;
      
      var doubleByteLangLocaleArray = ["ja-jp", "ko-kp", "zh-tw", "zh-cn"];
      var queryLength = 3;

      if(doubleByteLangLocaleArray.indexOf(this.rcLocaleJS) > -1){
        queryLength = 2;
      }
      
      this.dropIsVisible = this.query.length >= queryLength? true:false;

      // Clears typeahead on escape
      if (event.key === "Escape") {
        this.escapeBehaviors()
      }
      else if (event.key === "Enter") {
        this.originalQuery = this.query
		this.handleSubmit()
      }
      else {
	      if (this.query.length >= queryLength) {
	          // Updates term and sends get query if character is NOT a navigation key, space, or control, shift or alt.
	          // There will be variance between browsers and operating systems
	          // The purpose of this if statement is to reduce the number of queries sent to API without creating problems for internationalization. It is intended to filter out commonly used keys and is not targetting edge cases
	           if (
	             (
	               (event.key === "ArrowLeft") ||
	               (event.key === "ArrowRight") ||
	               (event.key === "ArrowUp") ||
	               (event.key === "ArrowDown") ||
	               (event.key === "Tab") ||
	               (event.key === "Home") ||
	               (event.key === "End") ||
	               (event.key === " ") ||
	               (event.key === "Shift") ||
	               (event.key === "Alt") ||
	               (event.key === "Enter") ||
	               (event.key === "Meta") ||
	               (event.key === "Fn") ||
	               (event.key === "Control") ||
	               (event.key === "ContextMenu") 
	             ) === false
	         ) {
	            this.originalQuery = this.query
	            //alert(this.query)
	            this.getJson()
	            this.clearActiveSearchTarget()
	          }
	          // Updates term shown in search bar and scrolls listed items on up or down
	          if (event.key === "ArrowUp" || event.key === "ArrowDown") {
	            this.navigateTypeAhead(event)
	          }          
	      }
      }
    },
    escapeBehaviors: function () {
      if (this.originalQuery === this.query) {
        // ; must be present for this to work properly
        this.query = ' ';
      } else if (this.query === ' ') {
        // If query is blank, do nothing - prevents error with else scenario below
        return
      } else {
        // ; must be present for this to work properly
        this.query = this.originalQuery;
        // errors on double escape if no check is present
        this.clearActiveSearchTarget();
      }

      // These are here to clear out the TA for next time it's opened
      this.clearResults();
    },
    handleSubmit: function () {
      if ( this.originalQuery === this.query ) {
        //window.location.href = '/search/?keyword=' + encodeURIComponent(this.query)
        axios.get("/ww/universal/display-views/DV166-serial-number-transaction.jsp", {
            params: {
                sn: encodeURIComponent(this.query),
                langId: this.rcLocaleJS.split("-")[0] + "_" + this.rcLocaleJS.split("-")[1].toUpperCase(),
                callType: "post",
                searchFrom: "header"
            }
        }).then(response => {
            if (typeof(response.data) != "undefined" 
                && typeof(response.data.results) != "undefined" 
                && typeof(response.data.results[0]) != "undefined" 
                && typeof(response.data.results[0].support_page_url) != "undefined") {
                window.location.href = response.data.results[0].support_page_url;
            } else {
                window.location.href = '/search/?keyword=' + encodeURIComponent(this.query)
            }
        }).catch((error) => {
            console.log(error);
            window.location.href = '/search/?keyword=' + encodeURIComponent(this.query)
        });
      }
      else {
			 if (this.isClassPresent('search-target active')){
			  document.getElementsByClassName('search-target active')[0].children[0].click()
			 }
        // Expects target element to have <li> <a href=""> </li> structure. If consumer terms column html structure for links is changed this will break.
      }
    },
    getJson: function () {
      axios
      //.get('http://localhost:3000/solrAutoSuggest-v4.json')
        .get(this.api, {
         params: {
           rcLocaleJS: this.rcLocaleJS,
           term: this.query
          }
        })
        .then(response => {
          this.JSONFile = response.data
          this.documentation = response.data.documentation || null
          this.knowledgeBase = response.data.knowledgeBase || null
          this.products = response.data.products.groups || []
          this.softwareDownloads = response.data.softwareDownloads || null
          this.articles = response.data.articles || null
          this.suggestions = response.data.suggestions || null
          this.keyMatches = response.data.keyMatches.docs || null
          this.productRelevanceScore = response.data.products.score || null
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
            this.JSONFile != null ? this.loading = false: this.loading
        })
    },
    navigateTypeAhead: function(event) {
      // updated input field on scroll while retaining original value
      // on three keystrokes
      const searchSuggestions = document.getElementsByClassName('search-target')
      const totalSuggestions = searchSuggestions.length - 1
      let currentElement = ''
      let currentElementIndex = -1
      let nextElement = ''

      // Note that getElementsByClassName is not an array but a NodeList, it shares some, but not all properties of an array

      // If - Checks if the user is already scrolling
      if (this.isClassPresent('search-target active')) {
        currentElement = (document.getElementsByClassName('search-target active')[0])
        currentElementIndex = [].indexOf.call(searchSuggestions, currentElement)
        // If - Checks if selection loops back to the input field with up keystroke on first or down keystroke on last because this is a special case
        if ((event.key === "ArrowDown" && currentElement === searchSuggestions[totalSuggestions]) ||
        (event.key === "ArrowUp" && currentElement === searchSuggestions[0])) {
          currentElement.classList.remove('active')
          this.query = this.originalQuery
        } else if (event.key === "ArrowUp") {
          nextElement = searchSuggestions[currentElementIndex - 1]
          this.query = nextElement.getAttribute('value')
          currentElement.classList.remove('active')
          nextElement.classList.add('active')
        } else if (event.key === "ArrowDown") {
          nextElement = searchSuggestions[currentElementIndex + 1]
          this.query = nextElement.getAttribute('value')
          currentElement.classList.remove('active')
          nextElement.classList.add('active')
        }
      //  Else place initial scroll
      } else {
        if (event.key === "ArrowUp") {
          nextElement = searchSuggestions[totalSuggestions]
          this.query = nextElement.getAttribute('value')
          nextElement.classList.add('active')
        } else if (event.key === "ArrowDown") {
          nextElement = searchSuggestions[0]
          this.query = nextElement.getAttribute('value')
          nextElement.classList.add('active')
        }
      }
    },
    clearActiveSearchTarget: function () {
      if (this.isClassPresent('search-target active'))
      {
        (document.getElementsByClassName('search-target active')[0]).classList.remove('active')
      }
    },
    toggleSearch: function () {
      // Given that the searchbar displays at desktop and is toggled at mobile by interacting with an icon, additional complexity is required beyond a normal toggle that binds to a variable stored in data
      const searchContainer = this.$refs.searchBarContainer
      const parentPlacement = this.$refs.searchTypeahead
      const parentPadding = parentPlacement.style.paddingLeft
      const containerPlacement = parentPlacement.getBoundingClientRect()
      if ( searchContainer.offsetParent === null ) {
        this.checkClientWidth(searchContainer, containerPlacement, parentPadding)
        searchContainer.style.display = 'block'
      } else {
        searchContainer.style.display = 'none'
      }
    },
    clearResults: function () {
      this.JSONFile = null;
      this.documentation = null;
      this.knowledgeBase = null;
      this.products = [];
      this.softwareDownloads = null;
      this.articles = null;
      this.suggestions = null;
      this.keyMatches = null;
      this.productRelevanceScore = null;
    },
    // Because the #typeahead-dropdown is absolutely positioned (as opposed to fixed which can not be used because it prevents scrolling) this function positions it properly but only at mobile
    checkClientWidth: function (searchContainer, containerPlacement, parentPadding) {
      if (document.body.clientWidth < 768) {
        searchContainer.style.left = (0 - containerPlacement.left - parentPadding) + 'px'
        searchContainer.style.width = document.body.clientWidth + 'px'
      }
    }
  }
}
</script>
