<template>

	<div class="search-header">
		<div class="container">
			<p class="search-info" id="results-returned" v-if="labels">
				{{allResults || 0}} {{labels['searchpageResultsReturned']}} <a :href="'/search/?keyword='+searchTerm">{{searchTerm}}</a>. 
				<span v-if="spellcheck == 'false'">{{labels['search.results.view.search.instead']}} <a :href="'/search/?keyword='+spellcheckQ+'&ignoreSpellCheck=true'">{{spellcheckQ}}</a>.</span>
			</p>
			<div class="search-header-box">
				<form @submit.prevent="handleSubmit" id="body-searchbar-container" action="/search/" method="post">
					<span class="search-glass"></span>
					<input id="body-searchbar"
			          type="text"
			          v-model="searchTerm"
			          autocomplete="off"
					  class="search-input" />
					<input type="submit" id="search-input-submit" class="search-submit hidden" />
					<span v-if="searchTerm && searchTerm.length > 1" v-on:click="searchTerm = ''" class="search-clear"></span>
				</form>
			</div>
		</div>
	</div>

</template>



<script>
import { eventBus } from './../event-bus.js'

export default{
  props: ['mobileRefinementsVisible','allResults','selectedFacets', 'searchTerm', 'spellcheck', 'spellcheckQ'],
  computed: {
    // Labels must be accessed dynamically
    labels: function () { return window.labels; }
  },
  methods: {
    clearFacetsHeading: function () {
      // While it is possible to clear the facet array here reseting checkboxes is trickier. While it possible to set checkboxes to false here instead it tells the refinement navigation component to reset them instead.  This is done so targeting code exists in only one location.
      eventBus.$emit('facetsClearedInHeading')
    },
    removeFacetHeading: function (activeFacetUniqueId) {
      // Similar to above
      eventBus.$emit('facetRemovedInHeading', activeFacetUniqueId)
    },
    showMobile: function () {
      eventBus.$emit('mobileRefinementMenu', !this.mobileRefinementsVisible)
    },
    handleSubmit: function () {
      window.location.href = '/search/?keyword=' + encodeURIComponent(this.searchTerm)
    }
  }
}
</script>
